import PropTypes from 'prop-types';
import React from 'react';

import Facebook from '../../images/icons/facebook.svg';
import Pinterest from '../../images/icons/pinterest.svg';
import Twitter from '../../images/icons/twitter.svg';

const Share = ({ image, name, size = 32, tags = [], url }) => {
	const pinterestTags = `#${tags.join(' #')}`;
	const twitterTags = tags.join(',');

	return (
		<>
			<a
				href={`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
					url
				)}&media=${encodeURIComponent(image)}&description=${encodeURIComponent(
					`${name} ${pinterestTags}`
				)}`}
				rel="noopener noreferrer"
				target="_blank"
				title={`${name} Pinterest`}
			>
				<img
					alt={`${name} Pinterest`}
					className={`img-grayscale img-icon-${size}`}
					src={Pinterest}
				/>
			</a>

			<a
				className="ml-3"
				href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
					url
				)}`}
				rel="noopener noreferrer"
				target="_blank"
				title={`${name} Facebook`}
			>
				<img
					alt={`${name} Facebook`}
					className={`img-grayscale img-icon-${size}`}
					src={Facebook}
				/>
			</a>

			<a
				className="ml-3"
				href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
					`${name}`
				)}&url=${encodeURIComponent(url)}&hashtags=${encodeURIComponent(
					twitterTags
				)}`}
				rel="noopener noreferrer"
				target="_blank"
				title={`${name} Twitter`}
			>
				<img
					alt={`${name} Twitter`}
					className={`img-grayscale img-icon-${size}`}
					src={Twitter}
				/>
			</a>
		</>
	);
};

Share.propTypes = {
	image: PropTypes.string,
	name: PropTypes.string,
	size: PropTypes.number,
	tags: PropTypes.string,
	url: PropTypes.string,
};

export default Share;
