import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Share from '../../components/tools/share';

const Post = ({ className, fields, frontmatter, html, single = true }) => {
	const date = frontmatter.date;
	const title = frontmatter.title || fields.slug;

	return (
		<article className={className}>
			{!single && (
				<div className="container">
					<h2 className="display-4 font-weight-lighter">
						<Link to={fields.slug} title={title}>
							{title}
						</Link>
					</h2>
					{date && <p className="small">{date}</p>}
				</div>
			)}

			<div
				className="container"
				dangerouslySetInnerHTML={{ __html: html }}
			></div>

			{single && (
				<div className="container mt-5 text-center">
					<Share
						image={frontmatter.image.childImageSharp.fluid.src}
						name={title}
						size={24}
						tags={['denizko']}
						url={fields.slug_url}
					/>
				</div>
			)}
		</article>
	);
};

Post.propTypes = {
	className: PropTypes.string,
	fields: PropTypes.shape({
		slug: PropTypes.string.isRequired,
		slug_url: PropTypes.string.isRequired,
	}),
	frontmatter: PropTypes.shape({
		date: PropTypes.string,
		image: PropTypes.string,
		title: PropTypes.string.isRequired,
	}),
	html: PropTypes.string.isRequired,
	single: PropTypes.bool,
};

export default Post;
