import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Framework from '../../components/framework';
import Paging from '../../components/blog/paging';
import Post from '../../components/blog/post';
import SEO from '../../components/framework/seo';

const PostTemplate = ({ data, location, pageContext }) => {
	const post = data.markdownRemark;

	const header = {
		image: post.frontmatter.image.childImageSharp.fluid.src,
		subTitle: post.frontmatter.date,
		title: post.frontmatter.title,
	};

	return (
		<Framework header={header} location={location}>
			<SEO
				description={post.frontmatter.excerpt}
				image={post.frontmatter.image.childImageSharp.fluid.src}
				location={location}
				published_time={post.frontmatter.date_original}
				tags={post.frontmatter.tags}
				title={header.title}
				type="article"
			/>
			<Post single={true} {...post} />
			<Paging next={pageContext.next} previous={pageContext.previous} />
		</Framework>
	);
};

PostTemplate.propTypes = {
	data: {
		markdownRemark: PropTypes.object.isRequired,
	},
	pageContext: {
		next: PropTypes.object,
		previous: PropTypes.object,
	},
	location: PropTypes.object.isRequired,
};

export default PostTemplate;

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			...MarkdownFragment
		}
	}
`;
