import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Paging = ({ next, previous }) => {
	return (
		<div className="container my-4">
			<div className="row">
				<div className="col">
					{next && (
						<Link
							className="btn btn-link"
							rel="next"
							to={next.url}
							title={next.title}
						>
							← {next.title}
						</Link>
					)}
				</div>
				<div className="col text-right">
					{previous && (
						<Link
							className="btn btn-link"
							rel="next"
							to={previous.url}
							title={previous.title}
						>
							{previous.title} →
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

Paging.propTypes = {
	next: PropTypes.shape({
		title: PropTypes.string,
		url: PropTypes.string,
	}),
	previous: PropTypes.shape({
		title: PropTypes.string,
		url: PropTypes.string,
	}),
};

export default Paging;
